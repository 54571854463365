@import '../../global.scss';

.filter {
    &-title {
        @extend .sub_heading_2;
        color: $headings;
        margin-bottom: 16px;
    }

    &--card {
        @include d_flex;
        justify-content: flex-start;
        gap: 12px;
        padding: 20px 20px;    
        &-image {
            width: 88px;
        }
    
        &--data {
            &-name {
                @extend .body_2;
                color: $headings;
                margin-bottom: 8px;
            }
    
            &-price {
                @include d_flex;
                justify-content: flex-start;
                gap: 8px;
                margin-bottom: 8px;
    
                &-new {
                    @extend .body_1;
                    font-weight: 700 !important;
                    color: $primary_900;
                }
    
                &-old {
                    @extend .body_5;
                    color: $label;
                    text-decoration: line-through;
                }
            }
    
            &-offer {
                @extend .body_5;
                @extend .sm_radius;
                padding: 2px 16px;
                color: $primary_900;
                background: $secondary_gradient;
                padding: 2px 16px;
                display: inline-flex;
            }
        }
    }

    &--details {
        &--item {
            @include d_flex;
            justify-content: space-between;
            height: 56px;
            padding: 16px 0;

            &:not(:last-child) {
                border-bottom: 1px dashed $line;
            }

            &-text {
                @extend .body_3;
                color: $label;
            }

            &-data {
                @extend .body_3;
                color: $headings;
            }
        }
    }
}

//Variant 
.variant {
    &-wrapper {
        margin-top: 16px;
        padding-bottom: 16px;

        &:not(:last-child) {
            border-bottom: 1px dashed $line;
        }
    }

    &--box {
        @include d_flex;
        flex-direction: column;
        gap: 4px;
        padding-top: 6px;
        border: 2px solid $primary_background;
        background-color: $primary_background;
        @extend .md_radius;
        text-align: center;

        &-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 12px;
        }

        &-top {
            @extend .overline;
            letter-spacing: 0.2px;
            color: $body;
            width: 100%;
        }

        &-mid {
            @extend .body_4;
            font: {
                size: 11px;
                weight: 500;
            }
            width: 100%;
        }

        &-bottom {
            @extend .md_radius;
            @extend .body_5;
            color: $body;
            width: 100%;
            padding: 4px;
        }

        &.selected {
            background-color: $primary_50;
            border-color: $primary_100;

            .variant--box-bottom {
                background-color: $white;
            }
        }
    }
}

//slider image
.sliderContainer{
    margin-bottom:40px;
    .sliderImage{
        display:block;
        img{
            display:block;
            width:100%;
        }
    }
    .slick-dots{
        bottom:-20px;
    }
}
/* Full-Screen Overlay */
.fullScreenOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:white;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:50px 0 10px 0;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 10000;
}

.fullScreenContent {
    width: 100%;
    height: 100%;
    overflow-y: auto;  /* Allows vertical scrolling */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fullScreenImage {
    margin-bottom:20px;
}

.fullScreenImage img {
    max-width: 100%;
    height: auto;
}
