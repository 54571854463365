.scrollToTop{
    position: fixed;
    width: 50px;
    height: 50px;
    line-height: 40px;
    background-color: #f8f8f8;
    border-radius: 50%;
    text-align: center;
    right:-100px;
    border: 2px solid #eee;
    transition: .3s;
    display: block;
    bottom: 120px;
    padding: 5px;
    z-index: 2;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg{
        width:15px;
    }
    &.active{
        right:0;
    }
}