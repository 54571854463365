@import "../../global.scss";

.session {
    &--action {
        @extend .action_btn_1;
        color: $primary_500;
        text-align: right;
    }

    &--modal {
        &-overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($color: $black, $alpha: 0.5);
            z-index: 6;
        }

        &-content {
            @extend .md_radius;
            background-color: $white;
            text-align: center;
            max-width: 293px;
            margin: 0 auto;
            padding: 26px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &-title {
                @extend .sub_heading_1;
                color: $headings;
                margin: 12px 0 4px;
            }

            &-text {
                @extend .body_3;
                color: $body;
                margin-bottom: 20px;
            }
        }

        &-buttons {
            @include d_flex;
            gap: 20px;

            button {
                flex-basis: 100%;
                border: none;
                box-shadow: none;
            }
        }
    }

    
   
}

.sheetBody{
    &.sessionForm{
        .input_group_container{
            margin-bottom:35px;
        }
        .error{        
            color: $error;
            position: absolute;
            left:0;
            bottom:-25px;
        }
    }
   
}


