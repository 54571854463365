@import "../../global.scss";
.plpWrapper {
    padding: 16px 20px;

    @media (max-width: 600px) {
        padding: 12px 16px;
    }

    .headingBox {
        margin-bottom: 12px;
    }
}

.productCategories {
    margin-bottom: 28px;
    
    &-title {
        @extend .sub_heading_1;
        color: $body;
        margin-bottom: 16px;
    }

    &--options {
        @include d_flex;
        justify-content: space-between;
        gap: 24px;
    }

    &--lists {
        @include d_flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 16px;
        list-style: none;
        max-width: 100%;
        overflow-x: scroll;
    }

    &--item {
        @extend .body_3;
        color: $body;
        background-color: $primary_background;
        padding: 6px 12px;
        border-radius: 999px;
        cursor: pointer;
        white-space: nowrap;

        &.active {
            color: $primary_500;
            background-color: $primary_100;
        }
    }

    &--action {
        @extend .sub_heading_2;
        color: $white;
        background-color: $primary_500;
        border-radius: 999px;
        padding: 8px 16px;
        min-width: 162px;
        text-align: center;
        &:hover{
            cursor: pointer;
            text-decoration: none;
            color: $white;
        }
    }
}

.cardWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
}