@import "../../global.scss";

.report {
    &-head {
        padding: 8px 16px 16px;
        background-color: $primary_50;
        position: relative;
        z-index: 2;
    }

    &--input {
        @include d_flex;
        justify-content: space-between;
        gap: 16px;

        &-wrapper {
            flex-basis: 100%;
        }

        .react-datepicker-wrapper {
            width: 100%;
        }

        &-label {
            @extend .body_3;
            color: $headings;
            display: block;
        }

        &-select {
            @extend .body_2;
            @extend .md_radius;
            border: 1px solid $primary_500;
            background-color: $white;
            color: $headings;
            padding: 8px 12px;
            box-shadow: none;
            width: 100%;
        }
    }

    &--options {
        @include d_flex;
        align-items: end;
        gap: 16px;
        margin-top: 16px;
        
        > button {
            flex-basis: 100%;
        }
    }

    &--action {
        &-btn {
            @extend .md_radius;
            @extend .action_btn_1;
            cursor: pointer;
            padding: 0 12px;
            white-space: nowrap;
            border: none;
            height: 40px;

            &.refreshBtn {
                background-color: $primary_200;
                color: $body
            }

            &.clearBtn {
                background-color: $accent_100;
                color: $body
            }
        }
    }

    &-body {
        padding: 16px 16px;
        position: relative;
        z-index: 1;
    }

    &--info {
        @extend .heading_6;
        color: $body;
        text-align: center;
        margin: 20px 0;
    }

    &--table {
        .report--action {
            &-btn {
                background: $primary_500;
                color: $white;

                &:not(:last-child) {
                    margin-right: 8px;
                }
            } 
        }
    }

    &-singleDownload {
        @extend .md_radius;
        @extend .action_btn_2;
        background-color: $primary_700;
        color: $white;
        padding: 6px 12px;
        border: none;
    }
}