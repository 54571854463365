@import "../../global.scss";

.fixedFooter {
    padding: 8px 16px;
    background-color: $white;
    box-shadow: 0px -4px 52px 0px rgba(0 0 0 / 8%);
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    &-btn {
        @include d_flex;
        gap: 12px;
        background: $button_gradient;
        width: 100%;
        margin: 0 auto;
    }

    .handler {
        margin: 23px 0 9px;
    }
}

.footerActions {
    @include d_flex;

    &--child {
        flex-basis: 100%;
    }

    &--total {
        @extend .body_3;
        color: $body !important;
        text-transform: capitalize !important;
    }
}