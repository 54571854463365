@import "../../global.scss";

body:has(.checkoutPage) {
    background-color: $primary_background;
}

.checkout {
    &--steps {
        @include d_flex;
        justify-content: space-between;
        gap: 8px;
        background: $secondary_background;
        padding: 8px 16px;

        &-item {
            @include d_flex;
            gap: 4px;
            @extend .body_4;
            color: $primary_700;
            position: relative;

            &:not(:first-child) {
                justify-content: flex-end;
                width: 100%;

                &:before {
                    content: "";
                    border: 1px dashed $primary_700;
                    width: 100%;
                }
            }
        }
    }

    &--bleedingSpace {
        padding: 12px 20px;
    }
    
    &--card {
        @extend .md_radius;
        background-color: $white;
        margin-bottom: 16px;
        position: relative;

        &-delete {
            padding: 8px;
            position: absolute;
            top: 6px;
            right: 8px;
        }

        &-body {
            @include d_flex;
            align-items: flex-start;
            gap: 8px;
            padding: 12px 16px 12px 8px;

            &-left {
                width: 72px;
                height: 72px;
            }

            &-right {
                flex: 0 0 100%;
                max-width: calc(100% - 80px);
            }
        }

        &-name {
            @extend .sub_heading_2;
            color: $headings;
            margin-bottom: 12px;
        }

        &-sizes {
            @include d_flex;
            justify-content: flex-start;
            gap: 24px;
            margin-bottom: 12px;

            &-list {
                @include d_flex;
                gap: 4px;
                background-color: $primary_background;
                padding: 4px 8px;
                @extend .body_4;
                color: $label;
                @extend .md_radius;

                &-options {
                    @extend .body_3;
                    color: $body;
                    border: none;
                    background-color: $primary_background;
                    padding: 0 2px;
                    appearance: none;

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        border: none;
                        box-shadow: none;
                    }
                }
            }
        }

        &-price {
            @include d_flex;
            justify-content: flex-start;
            gap: 8px;
            margin-bottom: 12px;

            &-new {
                @extend .sub_heading_1;
                color: $primary_700;
            }

            &-old {
                @extend .body_4;
                color: $placeholder;
                text-decoration: line-through;
            }
        }

        &-tags {
            @include d_flex;
            justify-content: flex-start;
            gap: 8px;
        }

        &-footer {
            border-top: 1px dashed $line;
        }

        &-action {
            @include d_flex;

            &-btn {
                padding: 12px 0;
                @include d_flex;
                gap: 4px;
                @extend .body_4;
                font-weight: 700;
                color: $label;
                flex-basis: 100%;
                position: relative;
                cursor: pointer;

                &:not(:first-child):before {
                    content: "";
                    width: 1px;
                    height: 40%;
                    background-color: $line;
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .removing-item{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-color:rgba(255,255,255,.8);
            @include d_flex;
            z-index: 2;

        }
    }

    &--empty {
        @include d_flex;
        @extend .heading_4;
        color: $headings;
        height: calc(100vh - 17vh);
        flex-direction: column;
    }

    .insuranceBox {
        @extend .md_radius;
        background-color: $white;
        padding: 12px 16px;
        @include d_flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 16px;

        &--head {
            @include d_flex;
            justify-content: space-between;
            width: 100%;

            &-wrap {
                @include d_flex;
                justify-content: flex-start;
                gap: 8px;

                &-text {
                    @extend .body_3;
                    color: $body;
                }
            }
        }

        &--body {
            @include d_flex;
            justify-content: space-between;
            width: 100%;

            &-left {
                @include d_flex;
                justify-content: flex-start;
                gap: 12px;
            }

            &-text {
                @extend .sub_heading_2;
                color: $body;
                margin: 0;
                @include d_flex;
                justify-content: flex-start;
                gap: 4px;
            }

            &-price {
                @extend .sub_heading_1;
                color: $primary_700;
                margin: 0;
            }
        }

        &--footer {
            width: 100%;

            &-text {
                @extend .body_4;
                color: $label;
                margin: 0;
                text-align: center;
            }
        }
    }

    .tabs {
        &-container {
            margin-top: 24px;
        }

        &-header {
            background-color: #ECECEC;
        }

        &-headers {
            background-color: #ECECEC;

            .tab-button {
                border: none;
            }
        }
    }
}