@import '../../global.scss';

.wallet {
    margin-bottom: 16px;

    &-title {
        @extend .sub_heading_2;
        color: $headings;
        margin-bottom: 12px;
        text-transform: uppercase;
    }

    &--wrapper {
        @include d_flex;
        gap: 16px;
    }

    &--card {
        flex-basis: 100%;
        @extend .md_radius;
        border: 1px solid $line;
        background-color: $white;
        margin-bottom: 28px;
        position: relative;

        &-head {
            @include d_flex;
            @extend .md_radius;
            font-family: $lato;
            font-size: 12px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 2.16px;
            text-align: center;
            color: $white;
            height: 42px;
            background: $placeholder;
            text-transform: uppercase;
        }

        &-body {
            padding: 12px 12px 24px;
        }

        &-data {
            &-text {
                @extend .caption_;
                color: $body;
                margin-bottom: 8px;
            }

            &-amount {
                @extend .sub_heading_1;
                color: $headings;
            }
        }

        &-action {
            @extend .overline;
            color: $headings;
            padding: 10px 20px;
            border: 1px solid $line;
            border-radius: 999px;
            background-color: $white;
            position: absolute;
            left: 50%;
            bottom: -20px;
            transform: translate(-50%, 0%);
        }

        &.candere {
            border-color: $primary_700;

            .wallet--card-head {
                background-color: $primary_700;
            }

            .wallet--card-action {
                border-color: $primary_700;
                color: $primary_700;
            }
        }

        &.promo {
            border-color: $promo;

            .wallet--card-head {
                background-color: $promo;
            }

            .wallet--card-action {
                border-color: $promo;
                color: $promo;
            }
        }
    }
}