@import '../../global.scss';

.paymentOptions {
    position:relative;
    &:after{
        content:"Loading Payment Method........";
        width:100%;
        height:100%;
        background-color:rgba(255,255,255,.5);
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size:14px;
        color:$label;
    }
    &.paymentLoading{
        &:after{
            content:none;
        }
    }
    &-title {
        @extend .sub_heading_2;
        color: $headings;
        margin-bottom: 12px;
        text-transform: uppercase;
    }

    &--wrapper {
        @include d_flex;
        flex-direction: column;
        gap: 20px;
    }

    &--box {
        @include d_flex;
        justify-content: space-between;
        @extend .md_radius;
        padding: 12px 16px;
        background-color: $white;
        width: 100%;

        &-left {
            @include d_flex;
            gap: 12px;
        }

        &-icon {
            width: 28px;
        }

        &-data {
            &-title {
                @extend .body_3;
                color: $headings;
                margin-bottom: 4px;
            }

            &-text {
                @extend .body_4;
                color: $body;
            }
        }
    }
}